import { useState, useEffect } from "react";
import Slider from "react-slick";
import Axios from 'axios';
import ALink from "~/components/features/alink";

let CategorySlider = ({ sectionTitle, lang, place }) => {
    const [categorySliders, setCategorySliders] = useState([]);
    var settings = {
        dots: false,
        infinite: true,
        autoPlay: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1124,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    let getCategories = async (place) => {
        await Axios({
            method: 'post',
            responseType: 'json',
            url: 'api/home/',
            data: {
                action: 'getHomeCategorySliders',
                place,
                lang
            },
        }).then(({ data }) => {
            setCategorySliders(data);
        })
    }

    useEffect(() => {
        getCategories(place);
    }, [place])


    return (
        <section className="category-slider">
            <div className="container">
                <div className="section-heading">
                    <h2 className="title align-self-center letter-spacing-normal" dangerouslySetInnerHTML={{ __html: sectionTitle }}></h2>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-sm-12 g-0">
                        <Slider {...settings}>
                            {
                                categorySliders.length > 0 ?
                                    categorySliders.map((item, index) =>
                                        <ALink href={item.action_link} key={index}>
                                            <div className="category-slider-item">
                                                <div className={`category-slider-item-content ${item.content_position} text-align-${item.text_align}`}>
                                                    <div className="category-slider-item-title" style={{ color: item.header_color }}>
                                                        {item.head}
                                                    </div>
                                                    <div className="category-slider-item-text" style={{ color: item.header_color }}>
                                                        {item.slidertext}
                                                    </div>
                                                </div>
                                                <div className="category-slider-item-image">
                                                    <img src={process.env.IMG_URL + 'category-sliders/' + item.img_file} alt={`category_slider_${index}`} />
                                                </div>
                                            </div>
                                        </ALink>
                                    )
                                    :
                                    new Array(6).fill(1).map((item, index) => (
                                        <div className="skel-pro p-3" key={"Skeleton:" + index}></div>
                                    ))
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default CategorySlider;