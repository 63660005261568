import { useContext, useState, useEffect } from 'react';
import Reveal from 'react-awesome-reveal';
import CryptoJS from 'crypto-js'
import Axios from 'axios';
import Marquee from 'react-fast-marquee';

// Import Custom Component
import HomeSlider from '~/components/partials/home/home-slider';
import SpecialPrices from '~/components/partials/home/special-prices';
import CategorySlider from '~/components/partials/home/category-slider';

// Import Utils
import { fadeInUpShorter } from '~/utils/data';
import { LangContext } from '~/context/lang';
import MetaTitle from '~/components/partials/elements/meta-title';
import OurPartners from '~/components/partials/home/our-partners';
import BannerModal from '~/components/features/modals/banner-modal';
import Banner from '~/components/partials/home/banner';
import TopInfoBanner from '~/components/partials/home/top-info-banner';
import IntroductionBanner from '~/components/partials/home/introducing-banner';
import CategoryList from '~/components/partials/home/category-list';

function Home() {
    let { getLang, lang, currency } = useContext(LangContext);
    let language = getLang("homePage");
    const [loading, setLoading] = useState(true);
    const [sorting, setSorting] = useState([]);
    const [topBanners, setTopBanners] = useState([]);
    const [headlines, setHeadlines] = useState([]);

    useEffect(() => {
        setLoading(true);
    }, [lang])

    /**** GENERATE TOKEN CODE ****/
    let userToken = window.localStorage.getItem('userToken');
    if (!userToken) {
        let rand = Math.floor(Math.random() * 10000);
        var hash = CryptoJS.SHA256(`mkhazinhttokkens${rand}`).toString();
        window.localStorage.setItem('userToken', hash);
    }

    let getHomeSorting = async () => {
        await Axios({
            method: 'post',
            responseType: 'json',
            url: 'api/home/',
            data: {
                lang,
                action: 'getHomeSorting'
            }
        }).then((homeSorting) => {
            setSorting(homeSorting.data);
        })
    }

    let getTopBannerInfos = async () => {
        await Axios({
            method: 'post',
            responseType: 'json',
            url: 'api/home/',
            data: {
                lang,
                action: 'getTopBannerInfo'
            }
        }).then((topBanners) => {
            setTopBanners(topBanners.data);
        })
    }

    useEffect(() => {
        getHomeSorting();
        getTopBannerInfos();
        setHeadlines(JSON.parse(window.localStorage.getItem('headlines')));
    }, []);

    return (
        <>
            <MetaTitle title={language.homePage} />
            <div className={`main home-page skeleton-body skel-shop-products ${loading ? '' : 'loaded'}`}>
                <TopInfoBanner language={language} topBanners={topBanners} />
                <IntroductionBanner language={language} topBanners={topBanners} />
                <CategoryList language={language} lang={lang} />
                <div className="container mb-3">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className=''>
                                <div className='d-flex'>
                                    <Marquee speed={150}>
                                        {
                                            headlines.length > 0 &&
                                            headlines.filter((p) => { return p.position == 'below' }).map((x, y) =>
                                                <div><span className="text-primary mx-10" style={{ fontSize: "1.7rem" }} key={y}>{x.headline}</span></div>
                                            )
                                        }
                                    </Marquee>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    sorting.length > 0 &&
                    sorting.map((s, y) =>
                        s['category'] == 'topSliders' ?
                            <Reveal keyframes={fadeInUpShorter} delay={800} duration={1000} triggerOnce key={y}>
                                <HomeSlider lang={lang} language={language} place={"top"} />
                            </Reveal>
                            : s['category'] == 'bottomSliders' ?
                                <Reveal keyframes={fadeInUpShorter} delay={1000} duration={1000} triggerOnce key={y}>
                                    <HomeSlider lang={lang} language={language} key={y} place={"bottom"} />
                                </Reveal>
                                : s['category'] == 'topCategories' ?
                                    <Reveal keyframes={fadeInUpShorter} delay={300} duration={1000} triggerOnce key={y}>
                                        <CategorySlider language={language} lang={lang} sectionTitle={s['name']} place={"top"} />
                                    </Reveal>
                                    : s['category'] == 'bottomCategories' ?
                                        <Reveal keyframes={fadeInUpShorter} delay={300} duration={1000} triggerOnce key={y}>
                                            <CategorySlider language={language} lang={lang} sectionTitle={s['name']} place={"bottom"} />
                                        </Reveal>
                                        : s['can_update'] == 1 ?
                                            <Reveal keyframes={fadeInUpShorter} delay={300} duration={1000} triggerOnce key={y}>
                                                <SpecialPrices language={language} lang={lang} sortInfo={s} currency={currency} />
                                            </Reveal>
                                            : s['category'] == 'blogs' ?
                                                // <Reveal keyframes={fadeInUpShorter} delay={300} duration={1000} triggerOnce>
                                                //     <Articles language={language} lang={lang} />
                                                // </Reveal>
                                                ''
                                                :
                                                s['category'] == 'ourPartners' ?
                                                    <Reveal keyframes={fadeInUpShorter} delay={300} duration={1000} triggerOnce key={y}>
                                                        <OurPartners language={language} lang={lang} />
                                                    </Reveal>
                                                    :
                                                    (s['banner_id'] > 0) ?
                                                        <Banner banner={s['banner_info']} language={language} />
                                                        :
                                                        ''
                    )
                }
            </div>
            <BannerModal language={language} />
        </>
    )
}

export default Home;