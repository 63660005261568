
import { useState, useEffect } from 'react';
import Axios from 'axios';
// import Custom Component
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getUserData, homeSliderSettings, shortHomeSliderSettings } from '~/utils';
import Product from '~/components/features/products/product';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ALink from '~/components/features/alink';

function SpecialPrices(props) {
    const { lang, currency, sortInfo } = props;
    const [products, setProducts] = useState([]);
    const currencies = JSON.parse(currency);
    const user = getUserData();
    const mode = sortInfo['category'];
    const sectionTitle = sortInfo['name'];
    const productLimit = sortInfo['product_limit'];
    const sectionBanner = {
        banner: sortInfo['section_banner'],
        banner_align: sortInfo['section_banner_align']
    }

    let pageTitle = `${sectionTitle}`;

    let getSpecialPricesProducts = async () => {
        await Axios({
            method: 'post',
            responseType: 'json',
            url: '/api/home/',
            headers: {
                "Authorization": user ? user.token : null,
            },
            data: {
                action: "getHomeProducts",
                filter: mode,
                lang: lang,
                currency: currencies.currency,
                limit: productLimit,
                userToken: window.localStorage.getItem('userToken') ? window.localStorage.getItem('userToken') : null
            }
        }).then((x) => {
            setProducts(x.data);
        }).catch((err) => {
            console.log(err);
        });
    }

    const sliderSettings = sectionBanner.banner ? shortHomeSliderSettings : homeSliderSettings;

    useEffect(() => {
        getSpecialPricesProducts();
    }, [lang, currency])

    const BannerSection = () => {
        const renderSlider = () => (
            <Slider {...sliderSettings}>
                {products.length > 0
                    ? products.slice(0, 12).map((item, index) => (
                        <Product lang={lang} product={item} key={`Featured:${index}`} />
                    ))
                    : new Array(6).fill(1).map((_, index) => (
                        <div className="skel-pro p-3" key={`Skeleton:${index}`} />
                    ))}
            </Slider>
        );

        const renderBanner = () => (
            sectionBanner.banner && (
                <div className={`col-lg-5 ${sectionBanner.banner_align === 'right' ? 'px-5' : 'pe-5'}`}>
                    <LazyLoadImage
                        src={`${process.env.IMG_URL}section-banners/${sectionBanner.banner}`}
                        alt={sectionBanner.banner}
                        width={500}
                        height={405}
                        effect="blur"
                    />
                </div>
            )
        );

        return (
            <>
                {sectionBanner.banner_align === 'right' ? (
                    <>
                        <div className={`col-lg-${sectionBanner.banner ? '7 pt-1' : '12'}`}>
                            {renderSlider()}
                        </div>
                        {renderBanner()}
                    </>
                ) : (
                    <>
                        {renderBanner()}
                        <div className={`col-lg-${sectionBanner.banner ? '7 pt-1' : '12'}`}>
                            {renderSlider()}
                        </div>
                    </>
                )}
            </>
        );
    };
    return (
        <div className="container">
            <section className="special-prices">
                <div className="section-heading">
                    <h2 className="title align-self-center letter-spacing-normal" dir='ltr' dangerouslySetInnerHTML={{ __html: pageTitle }}></h2>
                </div>
                <div className='row'>
                    <BannerSection />
                </div>
                <div className='d-flex justify-content-end'>
                    <ALink href={`/special-prices/?specialPages=${mode}`} className={"d-flex gap-4"}>
                        See More
                        <img src='/images/icons/see-more-btn.svg' alt='see-more-btn' />
                    </ALink>
                </div>
            </section>
        </div>
    )
}
export default SpecialPrices;
