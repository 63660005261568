import { isMobile } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ALink from '~/components/features/alink';

function Banner(props) {
    const { language, banner } = props;
    let bannerContentPosition = "";

    if (banner.content_position == "center") {
        bannerContentPosition = {
            left: "50%",
            transform: "translate(-50%,-50%)",
        }
    } else if (banner.content_position == "right") {
        bannerContentPosition = {
            left: "unset",
            right: "4rem"
        }
    }
    return (
        <>
            <div className='container py-5 banners'>
                <div className="banners-group">
                    {
                        <>
                            <div className={`banner banner-overlay`} style={{ textAlign: banner.text_align, backgroundColor: banner.bg_color }}>
                                {
                                    banner.actionLink != "" ?
                                        <a href={`${banner.actionLink}`}>
                                            <div className="lazy-overlay bg-2"></div>
                                            <LazyLoadImage
                                                src={`${process.env.IMG_URL}banners/${banner.imgFile}`}
                                                alt={banner.head}
                                                width={banner.width}
                                                height={banner.height}
                                                effect="blur"
                                            />
                                        </a>
                                        :
                                        <>
                                            <div className="lazy-overlay bg-2"></div>
                                            <LazyLoadImage
                                                src={`${process.env.IMG_URL}banners/${banner.imgFile}`}
                                                alt={banner.head}
                                                width={banner.width}
                                                height={banner.height}
                                                effect="blur"
                                            />
                                        </>

                                }

                                <div className="banner-content" style={{ color: banner.txt_color, ...bannerContentPosition }}>
                                    <h4 className="banner-subtitle"><a href={`${banner.actionLink}`} style={{ color: banner.txt_color }}>{banner.head}</a></h4>
                                    <h3 className="banner-title"><a href={`${banner.actionLink}`} style={{ color: banner.txt_color }} dangerouslySetInnerHTML={{ __html: banner.text }}></a></h3>
                                    {
                                        !isMobile &&
                                            banner.actionLink != "" ?
                                            banner.button_show == 1 ?
                                                <div style={{ textAlign: banner.button_position }}>
                                                    <ALink href={banner.actionLink} className={"btn btn-dark"} style={{ width: banner.button_width + "px", height: banner.button_height + "px", backgroundColor: banner.button_bg_color, borderColor: banner.button_bg_color, fontSize: banner.button_font_size + 'px', color: banner.button_text_color }}>
                                                        {banner.buttonText}
                                                    </ALink>
                                                </div>
                                                :
                                                ''
                                            :
                                            ''
                                    }
                                </div>
                            </div>
                            {
                                banner.groupInfo != "" &&
                                banner.groupInfo.map((b, y) =>
                                    <div className={`banner banner-overlay`} style={{ textAlign: b.text_align, backgroundColor: b.bg_color }} key={y}>
                                        {
                                            b.actionLink != "" ?
                                                <a href={`${b.actionLink}`}>
                                                    <div className="lazy-overlay bg-2"></div>
                                                    <LazyLoadImage
                                                        src={`${process.env.IMG_URL}banners/${b.imgFile}`}
                                                        alt={b.head}
                                                        width={b.width}
                                                        height={b.height}
                                                        effect="blur"
                                                    />
                                                </a>
                                                :
                                                <div>
                                                    <div className="lazy-overlay bg-2"></div>
                                                    <LazyLoadImage
                                                        src={`${process.env.IMG_URL}banners/${b.imgFile}`}
                                                        alt={b.head}
                                                        width={b.width}
                                                        height={b.height}
                                                        effect="blur"
                                                    />
                                                </div>
                                        }

                                        <div className="banner-content" style={{ color: b.txt_color, ...bannerContentPosition }}>
                                            <h4 className="banner-subtitle"><a href={`${b.actionLink}`} style={{ color: b.txt_color }}>{b.head}</a></h4>
                                            <h3 className="banner-title"><a href={`${b.actionLink}`} style={{ color: b.txt_color }} dangerouslySetInnerHTML={{ __html: b.text }}></a></h3>
                                            {
                                                !isMobile &&
                                                    b.actionLink != "" ?
                                                    b.button_show != "" ?
                                                        <div style={{ textAlign: b.button_position }}>
                                                            <ALink href={b.actionLink} className={"btn btn-dark"} style={{ width: b.button_width + "px", height: b.button_height + "px", backgroundColor: b.button_bg_color, borderColor: b.button_bg_color, fontSize: b.button_font_size + 'px', color: b.button_text_color }}>
                                                                {b.buttonText}
                                                            </ALink>
                                                        </div>
                                                        :
                                                        ''
                                                    :
                                                    ''
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </>
                    }
                </div>
            </div>
        </>
    )
}
export default Banner;